
























































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { differentAgeConsumeDetails } from '@/utils/echarts/echarts';
import * as Services from '@/services/api';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
@Component({
  components: {
    tdesignFooter,
  },
})
export default class extends Vue {
  $dayjs: any;
  excelRatioArr: any = [];
  excelAnalysisArr: any = [];
  beginTime = '';
  endTime = '';
  analysisOption = {};
  tips = true;
  analysisshow = true;
  ratioshow = true;
  customersOfNum: any = 0;
  failTips = '暂无数据';
  repeatRatio = '0%';
  mallName = '商场名称';
  competitionMall = '竞品名称';
  peopleNum = 0;
  competitionPeopleNum = 0;
  ratio: any = 0;
  competitionRatio: any = 0;
  day = 0;
  competitionDay = 0;
  $beaconAction: any;
  tipValue1 = '(1)竞争客流人数：在期间内同时到访过主场项目与竞品项目的客流人数。(2)竞争客流占比：竞争客流人数/项目客流人数。(3)竞争客流人均到访天数：竞争客流到访天数加和/竞争客流人数。(4)项目客流重合度：竞争客流人数/主场与竞品客流合并去重后人数。';
  tipValue2 = '竞争客流中该日期到访项目的人数/竞争客流人数(若客流在时间段多个日期到访，则统计多次)。';
  @Watch('$store.state.mall.monthDate', { immediate: false, deep: true })
  monthDateChange(val: string): void {
    this.beginTime = val ? val : '';
    this.endTime = val
      ? this.$dayjs(val)
        .add(1, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      : '';
    this.initData(
      this.$store.state.mall.mallId,
      this.$store.state.mall.competedModelObj.nMallId,
      this.$dayjs(val).format('YYYY-MM'),
    );
  }
  @Watch('$store.state.mall.mallId', { immediate: false, deep: true })
  mallIdChange(val: number): void {
    this.initData(
      val,
      this.$store.state.mall.competedModelObj.nMallId,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
    );
  }
  @Watch('$store.state.mall.competedModelObj.nMallId', {
    immediate: false,
    deep: true,
  })
  competeMallIdChange(val: string): void {
    this.initData(
      this.$store.state.mall.mallId,
      val,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
    );
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(val: boolean): void {
    const datas = [];
    if (val === true) {
      datas.push({
        sheetData: this.excelRatioArr,
        sheetName: '竞争客流占比',
        sheetFilter: ['date', 'isCompete', 'title', 'name', 'value'],
      });
      datas.push({
        sheetData: this.excelAnalysisArr.length
          ? this.excelAnalysisArr
          : [
            {
              date: this.$dayjs(this.$store.state.mall.monthDate).format('YYYY[年]MM[月]'),
              title: '客流到访率%',
              value: '暂无数据',
            },
          ],
        sheetName: '竞争客流到访率分析',
        sheetFilter: ['date', 'title', 'value'],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  mounted(): void {
    this.beginTime = this.$store.state.mall.monthDate;
    this.endTime = this.$dayjs(this.beginTime)
      .add(1, 'month')
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    const canvas = document.getElementById('canvas');
    const ctx = (canvas as any).getContext('2d');

    ctx.strokeStyle = 'transparent';

    const circle1 = { x: 60, y: 74, r: 60 };
    const circle2 = { x: 110, y: 74, r: 60 };

    // 左一
    ctx.save();
    // x,y,r,开始弧度，结束弧度，false顺时针，绘制源图像
    ctx.arc(circle1.x, circle1.y, circle1.r, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#0062FF';
    ctx.stroke();
    ctx.fill();

    // source-atop重合部分
    ctx.beginPath();
    ctx.fillStyle = '#74D857';
    ctx.globalCompositeOperation = 'source-atop';
    ctx.arc(circle2.x, circle2.y, circle2.r, 0, 2 * Math.PI, false);
    ctx.strokeStyle = '#40C8F5';
    ctx.lineWidth = 1;
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    // 源图像上展示之前绘制图像
    ctx.beginPath();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.arc(circle2.x, circle2.y, circle2.r, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#40C8F5';
    ctx.fill();

    this.initData(
      this.$store.state.mall.mallId,
      this.$store.state.mall.competedModelObj.nMallId,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
    );
  }
  async initData(
    mallid: number,
    competemallid: string,
    date: string,
  ): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.excelRatioArr = [];
    this.excelAnalysisArr = [];
    this.ratioshow = true;
    this.analysisshow = true;
    const mallId = 'mall_id';
    const competeMallId = 'compete_mall_id';

    const res = await Services.getPassengerFlowOverlapSvc({
      [mallId]: mallid,
      [competeMallId]: competemallid,
      date,
      duration: 'xm',
    });
    this.customersOfNum = Number(res.status) === 0 ? res.data.count : this.failTips;
    this.repeatRatio = Number(res.status) === 0 ? `${res.data.overlap_percnet}%` : this.failTips;
    this.mallName = this.$store.state.mall.mallName;
    this.competitionMall = this.$store.state.mall.competedModelObj.nName;
    this.peopleNum = Number(res.status) === 0 ? res.data.m_sum : this.failTips;
    this.competitionPeopleNum = Number(res.status) === 0 ? res.data.n_sum : this.failTips;
    this.ratio = Number(res.status) === 0 ? `${res.data.m_percnet}%` : this.failTips;
    this.competitionRatio = Number(res.status) === 0 ? `${res.data.n_percnet}%` : this.failTips;
    this.day = Number(res.status) === 0 ? res.data.m_daily_val : this.failTips;
    this.competitionDay = Number(res.status) === 0 ? res.data.n_daily_val : this.failTips;
    this.ratioshow = false;
    const dayList: any[] = [];
    let m = this.$store.state.mall.monthDate;
    do {
      dayList.push(m);
      m = this.$dayjs(m)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } while (
      this.$dayjs(m).isBefore(this.$dayjs(this.$store.state.mall.monthDate)
        .add(1, 'month')
        .format('YYYY-MM-DD'))
    );

    const mDuration = 'm_duration';
    const res1 = await Services.getPassengerFlowDailySvc({
      [mallId]: mallid,
      [competeMallId]: competemallid,
      date,
      [mDuration]: 'xm',
    });
    if (Number(res1.status === 0)) {
      this.analysisOption = differentAgeConsumeDetails(
        dayList,
        res1.data.m_daily_percentAry,
        res1.data.n_daily_percentAry,
        4,
        '',
        '到访率(%)',
        res1.data.mall_name[0].name,
        res1.data.compete_name[0].name,
        res1.data.mall_name[0].name,
        res1.data.compete_name[0].name,
      );
      res1.data.m_daily_percentAry.forEach((i: any, ind: number) => {
        this.excelAnalysisArr.push(Object.assign(
          { value: `${i}%` },
          {
            ...res1.data.mall_name[0],
            date: (dayList as any)[ind],
            title: '主场竞争客流到访率(%)',
          },
        ));
      });

      res1.data.n_daily_percentAry.forEach((i: any, ind: number) => {
        this.excelAnalysisArr.push(Object.assign(
          { value: `${i}%` },
          {
            ...res1.data.compete_name[0],
            date: (dayList as any)[ind],
            title: '竞品竞争客流到访率(%)',
          },
        ));
      });
      this.excelAnalysisArr.sort((a: any, b: any) => this.$dayjs(a.date).unix() - this.$dayjs(b.date).unix());
      this.analysisshow = false;
    } else {
      this.analysisOption = differentAgeConsumeDetails(
        dayList,
        [],
        [],
        4,
        '',
        '到访率(%)',
        this.$store.state.mall.mallName,
        this.$store.state.mall.competedModelObj.nName,
        this.$store.state.mall.mallName,
        this.$store.state.mall.competedModelObj.nName,
      );
      this.analysisshow = false;
    }
    this.excelRatioArr.push(
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '',
        title: this.$store.state.mall.mallName,
        name: '竞争客流人数(人)',
        value: res.data?.count ? res.data.count : this.failTips,
      },
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '',
        title: this.$store.state.mall.mallName,
        name: '项目客流重合度(%)',
        value: res.data?.overlap_percnet
          ? res.data.overlap_percnet
          : this.failTips,
      },
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '主场',
        title: this.$store.state.mall.mallName,
        name: '竞争客流人均到访天数(天)',
        value: res.data?.m_daily_val ? res.data.m_daily_val : this.failTips,
      },
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '主场',
        title: this.$store.state.mall.mallName,
        name: '客流人数(人)',
        value: res.data?.m_sum ? res.data.m_sum : this.failTips,
      },
      // 字段 percnet 错误
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '主场',
        title: this.$store.state.mall.mallName,
        name: '竞争客流占比(%)',
        value: res.data?.m_percnet ? res.data.m_percnet : this.failTips,
      },
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '竞品',
        title: this.$store.state.mall.competedModelObj.nName,
        name: '客流人数(人)',
        value: res.data?.n_sum ? res.data.n_sum : this.failTips,
      },
      // 字段 percnet 错误
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '竞品',
        title: this.$store.state.mall.competedModelObj.nName,
        name: '竞争客流占比(%)',
        value: res.data?.n_percnet ? res.data.n_percnet : this.failTips,
      },
      {
        date: this.$dayjs(date).format('YYYY-MM'),
        isCompete: '竞品',
        title: this.$store.state.mall.competedModelObj.nName,
        name: '竞争客流人均到访天数(天)',
        value: res.data?.m_daily_val ? res.data.m_daily_val : this.failTips,
      },
    );
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
}
